import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://world.english-news.live/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Live International News - English-News Live | Sky, Euronews, France
            24 & More
          </title>
          <meta
            name="description"
            content="Watch top international news channels live on English-News Live. Access Sky News, Euronews, Bloomberg, ABC News, Al Jazeera, and more. All rights reserved to original owners."
          />
          <meta
            name="keywords"
            content="live international news, English news live, Sky News live, Euronews live, France 24, Bloomberg live, ABC News, DW News, Al Jazeera live, USA news live, India Today, global news streaming, watch news live, world news channels"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            At English-News Live (www.english-news.live), we respect and
            prioritize your privacy. This privacy policy outlines how we
            collect, use, and safeguard information when you use our website. By
            accessing our website, you agree to the practices described here.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>
                We do not collect any personal information from users unless
                voluntarily provided via contact forms, subscriptions, or other
                interactive features. However, for statistical and performance
                analysis, we may collect non-personal data such as:
              </p>
              <ul className="dash-list">
                <li>Browser type and settings</li>
                <li>Device information</li>
                <li>Anonymous usage statistics</li>
              </ul>
              <p>
                This data is collected to enhance the user experience and is not
                linked to any personally identifiable information.
              </p>
            </li>
            <li>
              <h2>Use of External Content</h2>
              <p>
                Our website provides live streaming links to internationally
                recognized news channels, such as:
              </p>
              <ul className="dash-list">
                <li>Sky News Live</li>
                <li>Euronews Live</li>
                <li>France 24 Live</li>
                <li>Bloomberg Business News Live</li>
                <li>ABC News Live</li>
                <li>DW News Live</li>
                <li>USA TODAY Live</li>
                <li>CNBC Live</li>
                <li>India Today Live</li>
                <li>WION Live</li>
                <li>Channel NewsAsia (CNA) Live</li>
                <li>Newsmax TV Live</li>
                <li>Arirang News Live</li>
                <li>NDTV 24x7 Live</li>
                <li>ABC News Australia Live</li>
                <li>Al Jazeera Live</li>
              </ul>
              <p>
                The live news content and video streams available through these
                links are the property of their respective content owners.
                English-News Live does not host or modify this content. By
                clicking on any live news link, users are redirected to the
                official YouTube live streams of each respective channel. We are
                not responsible for the content, policies, or practices of these
                third-party providers.
              </p>
            </li>
            <li>
              <h2>Cookies and Tracking </h2>
              <p>
                www.english-news.live may use cookies for website analytics to
                track visitor interactions and enhance your browsing experience.
                These cookies do not collect personal information and can be
                disabled in your browser settings if preferred.
              </p>
            </li>
            <li>
              <h2> External Links</h2>
              <p>
                Our website may include links to other sites, particularly
                YouTube, to host live news broadcasts. This privacy policy
                applies only to www.english-news.live. We encourage you to
                review the privacy policies of any external sites you visit.
              </p>
            </li>
            <li>
              <h2>Contact Us</h2>
              <p>
                If you have any questions regarding this privacy policy or our
                practices, please reach out to us at hi@english-news.live.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
